<template>
  <div id="site-list">
    <!-- dialog -->
    <site-add-edit
      v-model="isSiteFormActive"
      :is-edit="isEdit"
      :site-d-t-o="selectedItem"
      @add-site="isSiteFormActive"
      @refetch-data="fetchSites"
    ></site-add-edit>

    <delete-confirmation
      v-model="isDeleteDialogActive"
      :selected-item="selectedItem"
      @delete-confirmation="submitDelete"
      @refetch-data="fetchSites"
    ></delete-confirmation>

    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="site-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="console.log('Export All Sites')"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            v-model="isSiteFormActive"
            color="primary"
            class="mb-4 me-3"
            @click="isSiteFormActive = !isSiteFormActive, isEdit = true, selectedItem = {}"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Site</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="siteListTable"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
        single-select
        show-select
        group-by="isActive"
        :group-desc="true"
      >
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = false, isSiteFormActive = !isSiteFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = true, isSiteFormActive = !isSiteFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="disableSite(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountLockOutline }}
                  </v-icon>
                  <span>{{ item.isActive ? 'Disable' : 'Activate' }}</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isDeleteDialogActive = !isDeleteDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'

import siteService from '@/services/SiteService'
import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import useSiteService from '@/store/useSiteService'
import SiteAddEdit from './SiteAddEdit.vue'

export default {
  components: { SiteAddEdit, DeleteConfirmation },
  setup() {
    const SITE_APP = 'app-site'

    // Register module
    if (!store.hasModule(SITE_APP)) store.registerModule(SITE_APP, siteService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SITE_APP)) store.unregisterModule(SITE_APP)
    })

    const {
      siteListTable,
      tableColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedRows,

      fetchSites,
      deleteSite,
      postSite,
    } = useSiteService()

    const isSiteFormActive = ref(false)
    const isDeleteDialogActive = ref(false)
    const isEdit = ref(false)
    const selectedItem = ref()

    return {
      siteListTable,
      tableColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedItem,
      selectedRows,

      isDeleteDialogActive,
      isSiteFormActive,
      isEdit,

      fetchSites,
      deleteSite,
      postSite,
      icons: {
        mdiAccountOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiPlus,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
    }
  },
  methods: {
    disableSite(item) {
      const site = item
      site.isActive = !item.isActive
      this.postSite(site)
    },
    submitDelete(siteDTO) {
      this.deleteSite(siteDTO)
    },
  },
}
</script>
