import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountRemoveOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useSiteList() {
  const siteListTable = ref([])
  const siteSettingListTable = ref([])
  const podList = ref([])
  const cellList = ref([])
  const bedList = ref([])
  const siteData = ref({})

  const tableColumns = [
    { text: 'NAME', value: 'display' },
    { text: 'ADDRESS', value: 'siteAddress' },
    { text: 'ACTIVE', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const settingTableColumns = [
    { text: 'NAME', value: 'setting.settingName' },
    { text: 'VALUE', value: 'value' },
    { text: 'DESCRIPTION', value: 'setting.description' },
    { text: 'ACTIVE', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const statusFilter = ref(null)
  const totalSiteListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['display'],
    sortDesc: [false],
  })
  const siteTotalLocal = ref([])
  const selectedRows = ref([])
  const selectedSite = ref([])
  const selectedPod = ref([])
  const selectedBed = ref([])
  const selectedCell = ref([])

  const siteSettingResponse = ref(null)

  // fetch data
  const fetchSites = () => {
    console.log(JSON.stringify(siteListTable.value))
    store
      .dispatch('app-site/fetchSites', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
      })
      .then(response => {
        siteListTable.value = response

        loading.value = false
      })
      .catch(error => {
        console.log(error)

        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Update Site List!')
        console.log('error :>> ', error.response)
      })
  }

  watch([searchQuery, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchSites()
  })

  // get data
  const fetchSite = siteId => {
    store
      .dispatch('app-site/getSite', siteId)
      .then(response => {
        console.log(response)

        loading.value = false
      })
      .catch(error => {
        alert(`error postfetchSiteSite due to ${error}`)
      })
  }

  watch([], () => {
    fetchSite()
  })

  // post data
  const postSite = siteDTO => {
    store
      .dispatch('app-site/postSite', siteDTO)
      .then(response => {
        console.log(response)

        loading.value = false
      })
      .catch(error => {
        alert(`error postSite due to ${error}`)
      })
  }

  watch([], () => {
    postSite(siteData)
  })

  // delete data
  const deleteSite = siteDTO => {
    store
      .dispatch('app-site/deleteSite', siteDTO)
      .then(response => {
        console.log(response)

        loading.value = false
      })
      .catch(error => {
        alert(`error deleteSite due to ${error}`)
      })
  }

  watch([], () => {
    deleteSite(siteData)
  })

  const fetchSitePods = () => {
    store
      .dispatch('app-site/fetchSitePods')
      .then(response => {
        podList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`error fetchSitePods due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedPod.value = []
    fetchSitePods()
  })

  const fetchSiteCells = () => {
    store
      .dispatch('app-site/fetchSiteCells')
      .then(response => {
        cellList.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`error fetchSiteCells due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedCell.value = []
    fetchSiteCells()
  })

  const fetchSiteBeds = () => {
    store
      .dispatch('app-site/fetchSiteBeds')
      .then(response => {
        bedList.value = response
        console.log(bedList.value)

        loading.value = false
      })
      .catch(error => {
        alert(`error fetchSiteBeds due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    selectedBed.value = []
    fetchSiteBeds()
  })

  // Site Settings

  // fetch site settings
  const fetchSiteSettings = () => {
    console.log('Fetch Settings')
    store
      .dispatch('app-site/fetchSiteSettings')
      .then(response => {
        siteSettingListTable.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`error fetchSiteSettings due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    fetchSiteSettings()
  })

  const postSiteSetting = siteSettingDTO => {
    console.log('Post Settings')
    store
      .dispatch('app-site/postSiteSetting', siteSettingDTO)
      .then(response => {
        siteSettingResponse.value = response

        loading.value = false
      })
      .catch(error => {
        alert(`error postSiteSetting due to ${error}`)
      })
  }

  watch([], () => {
    loading.value = true
    postSiteSetting()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveSiteStatusVariant = status => {
    if (status === 'Pending') return 'warning'
    if (status === 'Active') return 'success'
    if (status === 'Inactive') return 'secondary'

    return 'primary'
  }

  const resolveSiteTotalIcon = total => {
    if (total === 'Total sites') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Active sites') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending sites') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    siteListTable,
    tableColumns,
    searchQuery,
    statusFilter,
    totalSiteListTable,
    loading,
    options,
    siteTotalLocal,
    selectedRows,
    siteData,
    selectedSite,
    fetchSites,

    postSite,
    deleteSite,
    resolveSiteStatusVariant,
    resolveSiteTotalIcon,

    selectedPod,
    podList,
    fetchSitePods,

    selectedCell,
    cellList,
    fetchSiteCells,

    selectedBed,
    bedList,
    fetchSiteBeds,

    settingTableColumns,
    siteSettingListTable,
    fetchSiteSettings,

    siteSettingResponse,
    postSiteSetting,
  }
}
