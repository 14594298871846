<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="isSiteFormActive"
        persistent
        max-width="50%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Site Details</span>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="siteForm"
            >
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="siteDTO.id"
                    label="Site ID"
                    hint="GUID"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-switch
                    v-model="siteDTO.isActive"
                    color="primary"
                    :model-value="true"
                    label="Active Status"
                    :readonly="!isEdit"
                  >
                  </v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="siteDTO.code"
                    label="Site Code"
                    hint="demoil"
                    :readonly="!isEdit"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="siteDTO.display"
                    label="Site Display Name"
                    hint="Demo County IL"
                    :rules="[required]"
                    :readonly="!isEdit"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="siteDTO.siteContact"
                    label="Site Contact"
                    hint="John Doe"
                    type="Name"
                    :readonly="!isEdit"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="siteDTO.contactTitle"
                    label="Site Contact Title"
                    hint="Site Administrator"
                    :readonly="!isEdit"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="siteDTO.siteSigner"
                    label="Site Signer"
                    type="Name"
                    hint="John Doe"
                    :readonly="!isEdit"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="siteDTO.signerTitle"
                    label="Site Signer Title"
                    hint="Site Administrator"
                    :readonly="!isEdit"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="siteDTO.siteAddress"
                    type="address"
                    label="Site Address"
                    hint="720 Cool Springs Blvd. Ste# 100, Franklin TN, 37067"
                    :readonly="!isEdit"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="text-right">
                  <v-btn
                    color="error darken-1"
                    type="reset"
                    text
                    @click="closeForm()"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    color="primary darken-1"
                    text
                    @click="submitForm(siteDTO)"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import siteService from '@/services/SiteService'
import useSiteList from '@/store/useSiteService'

export default {
  model: {
    prop: 'isSiteFormActive',
    event: 'update:is-site-form-active',
  },
  props: {
    isSiteFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    siteDTO: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const siteForm = ref(null)
    const { postSite } = useSiteList()

    const validate = siteDTO => {
      if (siteForm.value.validate(siteDTO)) {
        return true
      }

      return false
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update:is-site-form-active', false)
    }

    const SITE_APP = 'app-site'

    // Register module
    if (!store.hasModule(SITE_APP)) store.registerModule(SITE_APP, siteService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SITE_APP)) store.unregisterModule(SITE_APP)
    })

    return {
      siteForm,
      validate,
      closeForm,
      refreshParent,

      postSite,

      required,

      icons: {
        mdiClose,
      },
    }
  },
  methods: {
    submitForm(siteDTO) {
      if (this.validate(siteDTO)) {
        this.postSite(siteDTO)
        this.closeForm()
        this.refreshParent()
      } else {
        //console.log('Not Valid')
      }
    },
  },
}
</script>
