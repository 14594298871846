import ApiConfig from './ApiConfig'
import {
  BedClient_V1,
  CellClient_V1,
  PodClient_V1,
  SiteClient_V1,
  SiteSettingClient_V1,
} from './generated/api.generated.v1'

import variables from '@/variables'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSites() {
      const api = new SiteClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSites()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getSite(context, siteId) {
      const api = new SiteClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSite(siteId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postSite(context, siteDTO) {
      const api = new SiteClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postSite(siteDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteSite(context, siteDTO) {
      const api = new SiteClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .deleteSite(siteDTO.id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSitePods() {
      const api = new PodClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSitePods()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getSitePod(context, podId) {
      const api = new PodClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSitePod(podId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSiteCells() {
      const api = new CellClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteCells()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSiteBeds() {
      const api = new BedClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteBeds()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Site Settings
    fetchSiteSettings() {
      const api = new SiteSettingClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteSettings()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postSiteSetting(context, payload) {
      const api = new SiteSettingClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postSiteSetting(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
