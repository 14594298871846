import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"25%"},model:{value:(_vm.isDeleteDialogActive),callback:function ($$v) {_vm.isDeleteDialogActive=$$v},expression:"isDeleteDialogActive"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Deletion Confirmation")])]),_c(VCardText,[_c('span',[_vm._v(" Are you sure you want to delete this?")])]),_c(VCardActions,[_c(VRow,[_c(VCol,{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":"error darken-1","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v(" Confirm ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }