<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="isDeleteDialogActive"
        persistent
        max-width="25%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Deletion Confirmation</span>
          </v-card-title>
          <v-card-text>
            <span> Are you sure you want to delete this?</span>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="error darken-1"
                  text
                  @click="closeDialog()"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="confirmDelete()"
                >
                  Confirm
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'isDeleteDialogActive',
    event: 'update:is-delete-dialog-active',
  },
  props: {
    isDeleteDialogActive: {
      type: Boolean,
      required: true,
    },
    selectedItem: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeDialog = () => {
      emit('update:is-delete-dialog-active', false)
    }

    const confirmDelete = () => {
      emit('delete-confirmation', props.selectedItem)
      closeDialog()
      refreshParent()
    }

    return {
      closeDialog,
      confirmDelete,
      refreshParent,
    }
  },
  mounted(){
    console.log('Delete', this.selectedItem)
  }
}
</script>
